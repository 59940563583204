import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { domainUrl, fetchAData, fetchData, uiUrl } from "../api";

export function MeetingDetailsScreen({
  onClickJoin,
  _handleOnCreateMeeting,
  participantName,
  setParticipantName,
  videoTrack,
  setVideoTrack,
  onClickStartMeeting,
}) {
  const [meetingId, setMeetingId] = useState("");
  const [meetingIdError, setMeetingIdError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(false);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(false);

  const [meetingData, setMeetingData] = useState(null);

  const search = useLocation().search;
  const url_u_id =new URLSearchParams(search).get("u_id");
  const url_m_id =new URLSearchParams(search).get("m_id");
  const url_type =new URLSearchParams(search).get("type");

  const navigate = useNavigate();
  const navigateToRoute = (url) => {
    navigate(url);
  };

  const navigateToSite = () => {
    let link = `${uiUrl}/landing_page`;
    window.location.href = link;
  };

  useEffect(() => {
    if (url_type === 'a') {
      // fetchAData({m_id: url_m_id}).then(response =>{
      //   console.log('res a 2')
      // })
      // .catch((error) => {
      //     console.log(error)
      // })

      let url =  `${domainUrl}/get_a_meeting`
      const options = {
        method: "POST",
        body: JSON.stringify({u_id: url_u_id, type: url_type, m_id: url_m_id}),
        // headers: { Authorization: token, "Content-Type": "application/json" },
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json"
        }
      };

      fetch(url, options)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setMeetingId(data.data.code)
        setParticipantName(data.data.user_name)
        setMeetingData(data.data)

        // console.log(data.data)
      })
      .catch((error) => console.error("error", error));
    } else if (url_type === 'p' || url_type === 'c') {

      let url =  `${domainUrl}/get_meeting`
      const options = {
        method: "POST",
        body: JSON.stringify({u_id: url_u_id, type: url_type, m_id: url_m_id}),
        // headers: { Authorization: token, "Content-Type": "application/json" },
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json"
        }
      };

      fetch(url, options)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setMeetingId(data.data.code)
        setParticipantName(data.data.user_name)
        setMeetingData(data.data)

        // console.log(data.data)
      })
      .catch((error) => console.error("error", error));

    } else {
      console.log('user not supported yet!!!');
    }
  }, [url_m_id, url_type, url_u_id]);

  return (
    <div
      className={`flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5`}
    >
      {/* {meetingData.meeting_status === 1 &&  meetingData.sub_state === true? (
        <div className="border border-solid border-gray-400 rounded-xl px-4 py-3  flex items-center justify-center">
          <p className="text-white text-base">
            {`Meeting code : ${meetingId}`}
          </p>
          <button
            className="ml-2"
            onClick={() => {
              navigator.clipboard.writeText(meetingId);
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 3000);
            }}
          >
            {isCopied ? (
              <CheckIcon className="h-5 w-5 text-green-400" />
            ) : (
              <ClipboardIcon className="h-5 w-5 text-white" />
            )}
          </button>
        </div>
      ) : isJoinMeetingClicked ? (
        <>
          <input
            defaultValue={meetingId}
            onChange={(e) => {
              setMeetingId(e.target.value);
            }}
            placeholder={"Enter meeting Id"}
            className="px-4 py-3 bg-gray-650 rounded-xl text-white w-full text-center"
          />
          {meetingIdError && (
            <p className="text-xs text-red-600">{`Please enter valid meetingId`}</p>
          )}
        </>
      ) : null} */}

      {(meetingData?.meeting_status === 1) &&  (meetingData?.sub_state === true) ?
        <>
          <input
            value={participantName}
            onChange={(e) => setParticipantName(e.target.value)}
            placeholder="Enter your name"
            className="px-4 py-3 mt-5 bg-gray-650 rounded-xl text-white w-full text-center"
          />

          {/* <p className="text-xs text-white mt-1 text-center">
            Your name will help everyone identify you in the meeting.
          </p> */}

          {(meetingData?.owner === true) || (meetingData?.member === true) ?

            <button
            style={{backgroundColor: `${participantName.length < 3 ? "grey" : "#16307D"}`}}
            disabled={participantName.length < 3}
            className={`w-full ${
              participantName.length < 3 ? "bg-gray-650" : "bg-purple-350"
            }  text-white px-2 py-3 rounded-xl mt-5`}
            onClick={(e) => {
              if (meetingData?.owner === true) {
                // if (videoTrack) {
                //   videoTrack.stop();
                //   setVideoTrack(null);
                // }
                // onClickStartMeeting();
                if (meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                  onClickJoin(meetingId);
                } else setMeetingIdError(true);
              } else {
                if (meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                  onClickJoin(meetingId);
                } else setMeetingIdError(true);
              }
            }}
            >
            {meetingData?.owner === true ? "Enter meeting" : "Join a meeting"}
            </button>

            :
              <button 
              disabled
              className={`w-full bg-gray-650 text-white px-2 py-3 rounded-xl mt-5`}
              
            >
              Not Allowed
            </button>
          
          }
          
        </>: <h6 className="text-white text-center">Sorry Meeting is yet to start or the coach has no active subscription and also check the meeting schedule!!!</h6>
      }

      {/* {!iscreateMeetingClicked && !isJoinMeetingClicked && (
        <div className="w-full md:mt-0 mt-4 flex flex-col">
          <div className="flex items-center justify-center flex-col w-full ">
            <button
              className="w-full bg-purple-350 text-white px-2 py-3 rounded-xl"
              onClick={async (e) => {
                const meetingId = await _handleOnCreateMeeting();
                setMeetingId(meetingId);
                setIscreateMeetingClicked(true);
              }}
            >
              Create a meeting
            </button>
            <button
              className="w-full bg-gray-650 text-white px-2 py-3 rounded-xl mt-5"
              onClick={(e) => {
                setIsJoinMeetingClicked(true);
              }}
            >
              Join a meeting
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
}
