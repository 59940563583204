const API_BASE_URL = "https://api.videosdk.live";
const VIDEOSDK_TOKEN = process.env.REACT_APP_VIDEOSDK_TOKEN;

// copied from their demo console
// const VIDEOSDK_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJiOGY3NGEyZS01NmNjLTRkMjEtYjczMi00YjZiNWRiNTA3ZmQiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY4ODY5MzEzNSwiZXhwIjoxNjg4Nzc5NTM1fQ.FCiydbIm9xTqi7EW4eCfiDcsVk5q3IFRN23K9MeaciM"

const API_AUTH_URL = process.env.REACT_APP_AUTH_URL;

export const getToken = async () => {
  if (VIDEOSDK_TOKEN && API_AUTH_URL) {
    console.error(
      "Error: Provide only ONE PARAMETER - either Token or Auth API"
    );
  } else if (VIDEOSDK_TOKEN) {
    return VIDEOSDK_TOKEN;
  } else if (API_AUTH_URL) {
    const res = await fetch(`${API_AUTH_URL}/get-token`, {
      method: "GET",
    });
    const { token } = await res.json();
    return token;
  } else {
    console.error("Error: ", Error("Please add a token or Auth Server URL"));
  }
};

export const fetchData = async (data) => {
  // console.log(type)
  let url =  `${domainUrl}/get_meeting`
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    // headers: { Authorization: token, "Content-Type": "application/json" },
    headers: {
      'Accept': 'application/json',
      "Content-Type": "application/json"
    }
  };

  await fetch(url, options)
  .then((response) => {
    // response.json()
    console.log('m response', response.json())
    // return response.json()
  })
  .catch((error) => console.error("error", error));
};

export const fetchAData = async (data) => {
  // console.log(type)
  let url =  `${domainUrl}/check_meeting`
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    // headers: { Authorization: token, "Content-Type": "application/json" },
    headers: {"Content-Type": "application/json"},
  };

  await fetch(url, options)
  .then((response) => {
    // response.json()
    console.log('a response', response.json())
    // return response.json()
  })
  .catch((error) => console.error("error", error));
};

export const createMeeting = async ({ token }) => {
  // console.log(token)
  const url = `${API_BASE_URL}/v2/rooms`;
  const options = {
    method: "POST",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const { roomId } = await fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.error("error", error));

  return roomId;
};

export const validateMeeting = async ({ roomId, token }) => {
  const url = `${API_BASE_URL}/v2/rooms/validate/${roomId}`;

  const options = {
    method: "GET",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const result = await fetch(url, options)
    .then((response) => response.json()) //result will have meeting id
    .catch((error) => console.error("error", error));

  return result ? result.roomId === roomId : false;
};


// const live = false;
const live = true;

export const mediaUrl = live === false? "http://127.0.0.1:8001/" : "https://api.nextlevel-football.com/public/";

export const uiUrl = live === false? "http://localhost:3000" : "https://nextlevel-football.com";

export const domainUrl = live === false? "http://127.0.0.1:8001/api" : "https://api.nextlevel-football.com/api";

export const meetingUrl = live === false? "http://localhost:3001" : "https://meeting.nextlevel-football.com";
